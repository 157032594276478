import { defineAsyncComponent } from 'vue'

const icons = {
  'add-circle': defineAsyncComponent(() => import('./AddCircle.vue')),
  add: defineAsyncComponent(() => import('./Add.vue')),
  agent: defineAsyncComponent(() => import('./Agent.vue')),
  'align-center': defineAsyncComponent(() => import('./AlignCenter.vue')),
  archive: defineAsyncComponent(() => import('./Archive.vue')),
  'arrow-down': defineAsyncComponent(() => import('./ArrowDown.vue')),
  'arrow-left': defineAsyncComponent(() => import('./ArrowLeft.vue')),
  'arrow-right': defineAsyncComponent(() => import('./ArrowRight.vue')),
  'arrow-up': defineAsyncComponent(() => import('./ArrowUp.vue')),
  'article-outline': defineAsyncComponent(() => import('./ArticleOutline.vue')),
  article: defineAsyncComponent(() => import('./Article.vue')),
  'attach-file': defineAsyncComponent(() => import('./AttachFile.vue')),
  'back-ios': defineAsyncComponent(() => import('./BackIos.vue')),
  back: defineAsyncComponent(() => import('./Back.vue')),
  'blog-main': defineAsyncComponent(() => import('./BlogMain.vue')),
  blog: defineAsyncComponent(() => import('./Blog.vue')),
  bold: defineAsyncComponent(() => import('./Bold.vue')),
  business: defineAsyncComponent(() => import('./Business.vue')),
  calendar: defineAsyncComponent(() => import('./Calendar.vue')),
  call: defineAsyncComponent(() => import('./Call.vue')),
  'camera-add-fill': defineAsyncComponent(() => import('./CameraAddFill.vue')),
  'camera-add-outline': defineAsyncComponent(() => import('./CameraAddOutline.vue')),
  'camera-add': defineAsyncComponent(() => import('./CameraAdd.vue')),
  camera: defineAsyncComponent(() => import('./Camera.vue')),
  campaign: defineAsyncComponent(() => import('./Campaign.vue')),
  cancel: defineAsyncComponent(() => import('./Cancel.vue')),
  cart: defineAsyncComponent(() => import('./Cart.vue')),
  'category-tag': defineAsyncComponent(() => import('./CategoryTag.vue')),
  category: defineAsyncComponent(() => import('./Category.vue')),
  'chat-fill': defineAsyncComponent(() => import('./ChatFill.vue')),
  chat: defineAsyncComponent(() => import('./Chat.vue')),
  'check-circle': defineAsyncComponent(() => import('./CheckCircle.vue')),
  'chevron-down': defineAsyncComponent(() => import('./ChevronDown.vue')),
  'chevron-left': defineAsyncComponent(() => import('./ChevronLeft.vue')),
  'chevron-right': defineAsyncComponent(() => import('./ChevronRight.vue')),
  'chevron-up': defineAsyncComponent(() => import('./ChevronUp.vue')),
  clock: defineAsyncComponent(() => import('./Clock.vue')),
  'close-fullscreen': defineAsyncComponent(() => import('./CloseFullscreen.vue')),
  close: defineAsyncComponent(() => import('./Close.vue')),
  concierge: defineAsyncComponent(() => import('./Concierge.vue')),
  'content-copy': defineAsyncComponent(() => import('./ContentCopy.vue')),
  'delete-fill': defineAsyncComponent(() => import('./DeleteFill.vue')),
  'delete-outline': defineAsyncComponent(() => import('./DeleteOutline.vue')),
  delivery: defineAsyncComponent(() => import('./Delivery.vue')),
  done: defineAsyncComponent(() => import('./Done.vue')),
  edit: defineAsyncComponent(() => import('./Edit.vue')),
  emoji: defineAsyncComponent(() => import('./Emoji.vue')),
  error: defineAsyncComponent(() => import('./Error.vue')),
  'file-add': defineAsyncComponent(() => import('./FileAdd.vue')),
  'file-search': defineAsyncComponent(() => import('./FileSearch.vue')),
  file: defineAsyncComponent(() => import('./File.vue')),
  'filter-list': defineAsyncComponent(() => import('./FilterList.vue')),
  folder: defineAsyncComponent(() => import('./Folder.vue')),
  'format-quote': defineAsyncComponent(() => import('./FormatQuote.vue')),
  'forward-ios': defineAsyncComponent(() => import('./ForwardIos.vue')),
  forward: defineAsyncComponent(() => import('./Forward.vue')),
  groups: defineAsyncComponent(() => import('./Groups.vue')),
  'help-outline': defineAsyncComponent(() => import('./HelpOutline.vue')),
  help: defineAsyncComponent(() => import('./Help.vue')),
  home: defineAsyncComponent(() => import('./Home.vue')),
  'horizontal-rule': defineAsyncComponent(() => import('./HorizontalRule.vue')),
  image: defineAsyncComponent(() => import('./Image.vue')),
  'info-outline': defineAsyncComponent(() => import('./InfoOutline.vue')),
  info: defineAsyncComponent(() => import('./Info.vue')),
  'job-post': defineAsyncComponent(() => import('./JobPost.vue')),
  'job-search': defineAsyncComponent(() => import('./JobSearch.vue')),
  language: defineAsyncComponent(() => import('./Language.vue')),
  license: defineAsyncComponent(() => import('./License.vue')),
  link: defineAsyncComponent(() => import('./Link.vue')),
  location: defineAsyncComponent(() => import('./Location.vue')),
  lock: defineAsyncComponent(() => import('./Lock.vue')),
  mail: defineAsyncComponent(() => import('./Mail.vue')),
  mention: defineAsyncComponent(() => import('./Mention.vue')),
  menu: defineAsyncComponent(() => import('./Menu.vue')),
  'mic-off': defineAsyncComponent(() => import('./MicOff.vue')),
  mic: defineAsyncComponent(() => import('./Mic.vue')),
  'more-android': defineAsyncComponent(() => import('./MoreAndroid.vue')),
  more: defineAsyncComponent(() => import('./More.vue')),
  notifications: defineAsyncComponent(() => import('./Notifications.vue')),
  'open-in-new': defineAsyncComponent(() => import('./OpenInNew.vue')),
  'pan-zoom': defineAsyncComponent(() => import('./PanZoom.vue')),
  payment: defineAsyncComponent(() => import('./Payment.vue')),
  person: defineAsyncComponent(() => import('./Person.vue')),
  play: defineAsyncComponent(() => import('./Play.vue')),
  'post-add': defineAsyncComponent(() => import('./PostAdd.vue')),
  'post-edit': defineAsyncComponent(() => import('./PostEdit.vue')),
  refresh: defineAsyncComponent(() => import('./Refresh.vue')),
  remove: defineAsyncComponent(() => import('./Remove.vue')),
  replay: defineAsyncComponent(() => import('./Replay.vue')),
  reply: defineAsyncComponent(() => import('./Reply.vue')),
  'rss-feed': defineAsyncComponent(() => import('./RssFeed.vue')),
  'school-outline': defineAsyncComponent(() => import('./SchoolOutline.vue')),
  school: defineAsyncComponent(() => import('./School.vue')),
  search: defineAsyncComponent(() => import('./Search.vue')),
  send: defineAsyncComponent(() => import('./Send.vue')),
  'service-post': defineAsyncComponent(() => import('./ServicePost.vue')),
  settings: defineAsyncComponent(() => import('./Settings.vue')),
  'share-ios': defineAsyncComponent(() => import('./ShareIos.vue')),
  share: defineAsyncComponent(() => import('./Share.vue')),
  'star-half': defineAsyncComponent(() => import('./StarHalf.vue')),
  'star-outline': defineAsyncComponent(() => import('./StarOutline.vue')),
  star: defineAsyncComponent(() => import('./Star.vue')),
  subscription: defineAsyncComponent(() => import('./Subscription.vue')),
  'swap-vert': defineAsyncComponent(() => import('./SwapVert.vue')),
  sync: defineAsyncComponent(() => import('./Sync.vue')),
  'task-alt': defineAsyncComponent(() => import('./TaskAlt.vue')),
  tool: defineAsyncComponent(() => import('./Tool.vue')),
  'translate-google': defineAsyncComponent(() => import('./TranslateGoogle.vue')),
  'video-off': defineAsyncComponent(() => import('./VideoOff.vue')),
  video: defineAsyncComponent(() => import('./Video.vue')),
  visibility: defineAsyncComponent(() => import('./Visibility.vue')),
  warning: defineAsyncComponent(() => import('./Warning.vue')),
  'work-outline': defineAsyncComponent(() => import('./WorkOutline.vue')),
  'yen-outline': defineAsyncComponent(() => import('./YenOutline.vue'))
}

const ICON_NAMES = {
  AddCircle: 'add-circle',
  Add: 'add',
  Agent: 'agent',
  AlignCenter: 'align-center',
  Archive: 'archive',
  ArrowDown: 'arrow-down',
  ArrowLeft: 'arrow-left',
  ArrowRight: 'arrow-right',
  ArrowUp: 'arrow-up',
  ArticleOutline: 'article-outline',
  Article: 'article',
  AttachFile: 'attach-file',
  BackIos: 'back-ios',
  Back: 'back',
  BlogMain: 'blog-main',
  Blog: 'blog',
  Bold: 'bold',
  Business: 'business',
  Calendar: 'calendar',
  Call: 'call',
  CameraAddFill: 'camera-add-fill',
  CameraAddOutline: 'camera-add-outline',
  CameraAdd: 'camera-add',
  Camera: 'camera',
  Campaign: 'campaign',
  Cancel: 'cancel',
  Cart: 'cart',
  CategoryTag: 'category-tag',
  Category: 'category',
  ChatFill: 'chat-fill',
  Chat: 'chat',
  CheckCircle: 'check-circle',
  ChevronDown: 'chevron-down',
  ChevronLeft: 'chevron-left',
  ChevronRight: 'chevron-right',
  ChevronUp: 'chevron-up',
  Clock: 'clock',
  CloseFullscreen: 'close-fullscreen',
  Close: 'close',
  Concierge: 'concierge',
  ContentCopy: 'content-copy',
  DeleteFill: 'delete-fill',
  DeleteOutline: 'delete-outline',
  Delivery: 'delivery',
  Done: 'done',
  Edit: 'edit',
  Emoji: 'emoji',
  Error: 'error',
  FileAdd: 'file-add',
  FileSearch: 'file-search',
  File: 'file',
  FilterList: 'filter-list',
  Folder: 'folder',
  FormatQuote: 'format-quote',
  ForwardIos: 'forward-ios',
  Forward: 'forward',
  Groups: 'groups',
  HelpOutline: 'help-outline',
  Help: 'help',
  Home: 'home',
  HorizontalRule: 'horizontal-rule',
  Image: 'image',
  InfoOutline: 'info-outline',
  Info: 'info',
  JobPost: 'job-post',
  JobSearch: 'job-search',
  Language: 'language',
  License: 'license',
  Link: 'link',
  Location: 'location',
  Lock: 'lock',
  Mail: 'mail',
  Mention: 'mention',
  Menu: 'menu',
  MicOff: 'mic-off',
  Mic: 'mic',
  MoreAndroid: 'more-android',
  More: 'more',
  Notifications: 'notifications',
  OpenInNew: 'open-in-new',
  PanZoom: 'pan-zoom',
  Payment: 'payment',
  Person: 'person',
  Play: 'play',
  PostAdd: 'post-add',
  PostEdit: 'post-edit',
  Refresh: 'refresh',
  Remove: 'remove',
  Replay: 'replay',
  Reply: 'reply',
  RssFeed: 'rss-feed',
  SchoolOutline: 'school-outline',
  School: 'school',
  Search: 'search',
  Send: 'send',
  ServicePost: 'service-post',
  Settings: 'settings',
  ShareIos: 'share-ios',
  Share: 'share',
  StarHalf: 'star-half',
  StarOutline: 'star-outline',
  Star: 'star',
  Subscription: 'subscription',
  SwapVert: 'swap-vert',
  Sync: 'sync',
  TaskAlt: 'task-alt',
  Tool: 'tool',
  TranslateGoogle: 'translate-google',
  VideoOff: 'video-off',
  Video: 'video',
  Visibility: 'visibility',
  Warning: 'warning',
  WorkOutline: 'work-outline',
  YenOutline: 'yen-outline'
} as const

export { icons, ICON_NAMES }
