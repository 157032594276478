// package: outsource
// file: gw/outsource/outsource_service.proto

import * as gw_outsource_outsource_service_pb from "../../gw/outsource/outsource_service_pb";
import * as google_protobuf_wrappers_pb from "google-protobuf/google/protobuf/wrappers_pb";
import * as domain_outsource_pb from "../../domain/outsource_pb";
import * as domain_search_outsource_pb from "../../domain/search_outsource_pb";
import * as domain_enumeration_outsource_status_pb from "../../domain/enumeration/outsource_status_pb";
import * as domain_enumeration_outsource_payment_method_type_pb from "../../domain/enumeration/outsource_payment_method_type_pb";
import * as domain_enumeration_outsource_working_day_type_pb from "../../domain/enumeration/outsource_working_day_type_pb";
export class OutsourceService {
  static serviceName = "outsource.OutsourceService";
}
export namespace OutsourceService {
  export class CreateDraftOutsource {
    static readonly methodName = "CreateDraftOutsource";
    static readonly service = OutsourceService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = gw_outsource_outsource_service_pb.CreateOutsourceRequest;
    static readonly responseType = gw_outsource_outsource_service_pb.CreateOutsourceResponse;
  }
  export class CreatePublishOutsource {
    static readonly methodName = "CreatePublishOutsource";
    static readonly service = OutsourceService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = gw_outsource_outsource_service_pb.CreateOutsourceRequest;
    static readonly responseType = gw_outsource_outsource_service_pb.CreateOutsourceResponse;
  }
  export class UpdateOutsource {
    static readonly methodName = "UpdateOutsource";
    static readonly service = OutsourceService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = gw_outsource_outsource_service_pb.UpdateOutsourceRequest;
    static readonly responseType = gw_outsource_outsource_service_pb.UpdateOutsourceResponse;
  }
  export class UpdateStatusPublish {
    static readonly methodName = "UpdateStatusPublish";
    static readonly service = OutsourceService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = gw_outsource_outsource_service_pb.UpdateStatusRequest;
    static readonly responseType = gw_outsource_outsource_service_pb.UpdateStatusResponse;
  }
  export class UpdateStatusFinish {
    static readonly methodName = "UpdateStatusFinish";
    static readonly service = OutsourceService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = gw_outsource_outsource_service_pb.UpdateStatusRequest;
    static readonly responseType = gw_outsource_outsource_service_pb.UpdateStatusResponse;
  }
  export class UpdateStatusStop {
    static readonly methodName = "UpdateStatusStop";
    static readonly service = OutsourceService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = gw_outsource_outsource_service_pb.UpdateStatusRequest;
    static readonly responseType = gw_outsource_outsource_service_pb.UpdateStatusResponse;
  }
  export class GetMyOutsources {
    static readonly methodName = "GetMyOutsources";
    static readonly service = OutsourceService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = gw_outsource_outsource_service_pb.GetMyOutsourcesRequest;
    static readonly responseType = gw_outsource_outsource_service_pb.GetMyOutsourcesResponse;
  }
  export class SearchOutsources {
    static readonly methodName = "SearchOutsources";
    static readonly service = OutsourceService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = gw_outsource_outsource_service_pb.SearchOutsourcesRequest;
    static readonly responseType = gw_outsource_outsource_service_pb.SearchOutsourcesResponse;
  }
  export class SetBookmark {
    static readonly methodName = "SetBookmark";
    static readonly service = OutsourceService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = gw_outsource_outsource_service_pb.SetBookmarkRequest;
    static readonly responseType = gw_outsource_outsource_service_pb.SetBookmarkResponse;
  }
  export class GetOutsourceDetail {
    static readonly methodName = "GetOutsourceDetail";
    static readonly service = OutsourceService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = gw_outsource_outsource_service_pb.GetOutsourceDetailRequest;
    static readonly responseType = gw_outsource_outsource_service_pb.GetOutsourceDetailResponse;
  }
  export class GetMyOutsourceDetail {
    static readonly methodName = "GetMyOutsourceDetail";
    static readonly service = OutsourceService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = gw_outsource_outsource_service_pb.GetMyOutsourceDetailRequest;
    static readonly responseType = gw_outsource_outsource_service_pb.GetMyOutsourceDetailResponse;
  }
  export class IsAuthorized {
    static readonly methodName = "IsAuthorized";
    static readonly service = OutsourceService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = gw_outsource_outsource_service_pb.IsAuthorizedRequest;
    static readonly responseType = gw_outsource_outsource_service_pb.IsAuthorizedResponse;
  }
  export class GetMyBookmarks {
    static readonly methodName = "GetMyBookmarks";
    static readonly service = OutsourceService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = gw_outsource_outsource_service_pb.GetMyBookmarksRequest;
    static readonly responseType = gw_outsource_outsource_service_pb.GetMyBookmarksResponse;
  }
}
