// source: apigateway/ordermade_request/v2/ordermade_request.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.ordermade_request.v2.AddOrdermadeRequestReply', null, global);
goog.exportSymbol('proto.ordermade_request.v2.AddOrdermadeRequestRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ordermade_request.v2.AddOrdermadeRequestRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ordermade_request.v2.AddOrdermadeRequestRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ordermade_request.v2.AddOrdermadeRequestRequest.displayName = 'proto.ordermade_request.v2.AddOrdermadeRequestRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ordermade_request.v2.AddOrdermadeRequestReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ordermade_request.v2.AddOrdermadeRequestReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ordermade_request.v2.AddOrdermadeRequestReply.displayName = 'proto.ordermade_request.v2.AddOrdermadeRequestReply';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ordermade_request.v2.AddOrdermadeRequestRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ordermade_request.v2.AddOrdermadeRequestRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ordermade_request.v2.AddOrdermadeRequestRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ordermade_request.v2.AddOrdermadeRequestRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsJson: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sourceTalkroomId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    isSubscription: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    subscriptionMinCycleNumber: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ordermade_request.v2.AddOrdermadeRequestRequest}
 */
proto.ordermade_request.v2.AddOrdermadeRequestRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ordermade_request.v2.AddOrdermadeRequestRequest;
  return proto.ordermade_request.v2.AddOrdermadeRequestRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ordermade_request.v2.AddOrdermadeRequestRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ordermade_request.v2.AddOrdermadeRequestRequest}
 */
proto.ordermade_request.v2.AddOrdermadeRequestRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemsJson(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSourceTalkroomId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSubscription(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSubscriptionMinCycleNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ordermade_request.v2.AddOrdermadeRequestRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ordermade_request.v2.AddOrdermadeRequestRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ordermade_request.v2.AddOrdermadeRequestRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ordermade_request.v2.AddOrdermadeRequestRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsJson();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSourceTalkroomId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getIsSubscription();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getSubscriptionMinCycleNumber();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional string items_json = 1;
 * @return {string}
 */
proto.ordermade_request.v2.AddOrdermadeRequestRequest.prototype.getItemsJson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ordermade_request.v2.AddOrdermadeRequestRequest} returns this
 */
proto.ordermade_request.v2.AddOrdermadeRequestRequest.prototype.setItemsJson = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 source_talkroom_id = 2;
 * @return {number}
 */
proto.ordermade_request.v2.AddOrdermadeRequestRequest.prototype.getSourceTalkroomId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.ordermade_request.v2.AddOrdermadeRequestRequest} returns this
 */
proto.ordermade_request.v2.AddOrdermadeRequestRequest.prototype.setSourceTalkroomId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool is_subscription = 3;
 * @return {boolean}
 */
proto.ordermade_request.v2.AddOrdermadeRequestRequest.prototype.getIsSubscription = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ordermade_request.v2.AddOrdermadeRequestRequest} returns this
 */
proto.ordermade_request.v2.AddOrdermadeRequestRequest.prototype.setIsSubscription = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional int32 subscription_min_cycle_number = 4;
 * @return {number}
 */
proto.ordermade_request.v2.AddOrdermadeRequestRequest.prototype.getSubscriptionMinCycleNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.ordermade_request.v2.AddOrdermadeRequestRequest} returns this
 */
proto.ordermade_request.v2.AddOrdermadeRequestRequest.prototype.setSubscriptionMinCycleNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ordermade_request.v2.AddOrdermadeRequestReply.prototype.toObject = function(opt_includeInstance) {
  return proto.ordermade_request.v2.AddOrdermadeRequestReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ordermade_request.v2.AddOrdermadeRequestReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ordermade_request.v2.AddOrdermadeRequestReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    redirectUrl: jspb.Message.getFieldWithDefault(msg, 1, ""),
    requestId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    isRegisteredTaxOperator: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ordermade_request.v2.AddOrdermadeRequestReply}
 */
proto.ordermade_request.v2.AddOrdermadeRequestReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ordermade_request.v2.AddOrdermadeRequestReply;
  return proto.ordermade_request.v2.AddOrdermadeRequestReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ordermade_request.v2.AddOrdermadeRequestReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ordermade_request.v2.AddOrdermadeRequestReply}
 */
proto.ordermade_request.v2.AddOrdermadeRequestReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRedirectUrl(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRequestId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsRegisteredTaxOperator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ordermade_request.v2.AddOrdermadeRequestReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ordermade_request.v2.AddOrdermadeRequestReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ordermade_request.v2.AddOrdermadeRequestReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ordermade_request.v2.AddOrdermadeRequestReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRedirectUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRequestId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getIsRegisteredTaxOperator();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string redirect_url = 1;
 * @return {string}
 */
proto.ordermade_request.v2.AddOrdermadeRequestReply.prototype.getRedirectUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ordermade_request.v2.AddOrdermadeRequestReply} returns this
 */
proto.ordermade_request.v2.AddOrdermadeRequestReply.prototype.setRedirectUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 request_id = 2;
 * @return {number}
 */
proto.ordermade_request.v2.AddOrdermadeRequestReply.prototype.getRequestId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.ordermade_request.v2.AddOrdermadeRequestReply} returns this
 */
proto.ordermade_request.v2.AddOrdermadeRequestReply.prototype.setRequestId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool is_registered_tax_operator = 3;
 * @return {boolean}
 */
proto.ordermade_request.v2.AddOrdermadeRequestReply.prototype.getIsRegisteredTaxOperator = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ordermade_request.v2.AddOrdermadeRequestReply} returns this
 */
proto.ordermade_request.v2.AddOrdermadeRequestReply.prototype.setIsRegisteredTaxOperator = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


goog.object.extend(exports, proto.ordermade_request.v2);
