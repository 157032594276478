import { onMounted, onUnmounted, ref, useRouter } from '@nuxtjs/composition-api'
import Vue from 'vue'
import { Route } from 'vue-router'
import Util from '~/assets/javascripts/util'
import dayjs from 'dayjs'

/**
 * リアクティブを保ったままObject.assign同様の合成を行う
 * @param target
 * @param others
 */
export function assignReactive<T extends object>(target: T, others: T) {
  Object.entries(others).forEach(([otherKey, otherValue]) => {
    if (!target.hasOwnProperty(otherKey)) {
      Vue.set<T>(target, otherKey, otherValue)
    } else {
      target[otherKey] = otherValue
    }
  })
}

export function isSingleQuery(queryParam: Route['query'][string]): queryParam is string {
  return !Array.isArray(queryParam)
}

/**
 * 日付を比較して差分の日数を返す
 * @param startDate
 * @param endDate
 */
export function getDateDiff(startDate: dayjs.ConfigType, endDate: dayjs.ConfigType): number {
  const dateFrom = Util.dayjs(startDate).startOf('day')
  const dateTo = Util.dayjs(endDate).startOf('day')
  return dateTo.diff(dateFrom, 'day')
}

export type GetTimeArrayParamsType = {
  startHour: number
  startMinute: number
  endHour: number
  endMinute: number
  isStripFirstZero?: boolean // hourの0埋めしないフラグ
}

/**
 * 30分刻みの時間の配列を作成する ['06:00', '06:30', '07:00', ...]
 * @param params
 */
export const getTimeArray = (params: GetTimeArrayParamsType): string[] => {
  const times: string[] = []
  let hour: number = params.startHour
  let minute: number = params.startMinute
  do {
    const padHour: string = params.isStripFirstZero ? String(hour) : String(hour).padStart(2, '0')
    if (minute === 0) {
      times.push(padHour + ':00')
      minute = 30
    } else {
      times.push(padHour + ':30')
      minute = 0
      hour++
    }
  } while (hour < params.endHour || (hour == params.endHour && minute <= params.endMinute))
  return times
}

// 時間の文字列を数値に変換する '02:30' → 2.5
export const convertDateStringToNumber = (date: string): number =>
  Number(date.slice(0, 2)) + Number(date.slice(-2)) / 60

// 時間表示用に0埋め削除
export const trimZeroPadding = (time: string): string => {
  if (time.startsWith('0')) {
    return time.slice(1)
  }
  return time
}

// スマホを回転させたときに、回転のアニメーションが終わるまで待つ
export const waitOrientationChanged = () => {
  let height = 0
  return Util.waitUntilWithLimit(() => {
    if (window.innerHeight === height) {
      return true
    }
    height = window.innerHeight
    return false
  })
}

export const useMeasureDisplayHeight = () => {
  const displayHeight = ref('100vh')
  const updateDisplayHeight = () => (displayHeight.value = `${window.innerHeight}px`)
  const orientationchangeUpdate = () => waitOrientationChanged().then(updateDisplayHeight)

  if (process.client) {
    updateDisplayHeight()
    window.addEventListener('resize', updateDisplayHeight, { passive: true })
    // 画面回転イベント
    window.addEventListener('orientationchange', orientationchangeUpdate, { passive: true })
  }

  onUnmounted(() => {
    window.removeEventListener('resize', updateDisplayHeight)
    window.removeEventListener('orientationchange', orientationchangeUpdate)
  })

  return { displayHeight }
}

// 離脱前にアラートを表示する。
// SSR、CSR両方に対応
export const onTransitionBeforeAlertListener = ({
  condition = () => false,
  message
}: {
  condition: () => boolean
  message: string
}) => {
  const displayAlert = e => {
    if (condition()) {
      e.preventDefault()
      e.returnValue = message
    }
  }
  // CSR時に遷移先でアラートが表示されないようにフラグ管理
  const finishedTransition = ref(false)

  const add = () => {
    finishedTransition.value = false
    window.addEventListener('beforeunload', displayAlert)
  }
  const remove = () => {
    finishedTransition.value = true
    window.removeEventListener('beforeunload', displayAlert)
  }
  onMounted(add)
  onUnmounted(remove)

  const router = useRouter()
  router.beforeResolve((_to, _from, next) => {
    let ok = true
    if (!finishedTransition.value && condition()) {
      ok = window.confirm(message)
    }
    if (ok) {
      next()
    } else {
      next(false)
    }
  })

  return { addTransitionBeforeAlertListener: add, removeTransitionBeforeAlertListener: remove }
}
