import { grpc } from '@improbable-eng/grpc-web'
import { NodeHttpTransport } from '@improbable-eng/grpc-web-node-http-transport'
import { commonMetaData } from '~/store/_helpers/common-helper'
import { AddOrdermadeRequestRequest } from '~/stub/apigateway/ordermade_request/v2/ordermade_request_pb'
import { OrdermadeRequestService as OrdermadeRequestServiceV2 } from '~/stub/apigateway/ordermade_request/v2/ordermade_request_pb_service'
import { GetOrdermadeRequestEditMasterRequest } from '~/stub/apigateway/ordermade_request/ordermade_request_pb'
import { OrdermadeRequestService } from '~/stub/apigateway/ordermade_request/ordermade_request_pb_service'

// initial state
export const state = () => ({
  providerId: 0,
  providerName: '',
  budgetsList: [],
  isAvailableWithholding: false,
  redirectUrl: ''
})

// getters
export const getters = {}

// TODO: ダイナミックインポートをやめることでハッシュ値の競合を回避する、恒久対応後元に戻す
// actions
export const actions = {
  async getOrdermadeRequestEditMaster({ commit, rootState }, data) {
    // const [
    //   { GetOrdermadeRequestEditMasterRequest },
    //   { OrdermadeRequestService }
    // ] = await Promise.all([
    //   import('~/stub/apigateway/ordermade_request/ordermade_request_pb'),
    //   import('~/stub/apigateway/ordermade_request/ordermade_request_pb_service')
    // ])

    const params = data.params
    const metadata = commonMetaData(rootState)
    const request = new GetOrdermadeRequestEditMasterRequest()
    request.setProviderId(params.providerId)
    if (params.talkroomId) {
      request.setTalkroomId(params.talkroomId)
    }

    const reply = await new Promise((resolve, reject) => {
      grpc.invoke(OrdermadeRequestService.GetOrdermadeRequestEditMaster, {
        request,
        metadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          const decodedMessage = this.$util.getGrpcErrorMessage(trailers, process.client)
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode, message: decodedMessage })
          }
        }
      })
    })

    commit('RECEIVE_ORDERMADE_REQUEST', reply)
  },
  async addOrdermadeRequest({ commit, rootState }, data) {
    const params = data.params
    const metadata = commonMetaData(rootState)
    const request = new AddOrdermadeRequestRequest()

    request.setItemsJson(JSON.stringify(params))
    request.setSourceTalkroomId(data.sourceTalkroomId)
    request.setIsSubscription(data.isSubscription)
    request.setSubscriptionMinCycleNumber(data.subscriptionMinCycleNumber)

    const reply = await new Promise((resolve, reject) => {
      grpc.invoke(OrdermadeRequestServiceV2.AddOrdermadeRequest, {
        request,
        metadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          if (grpcCode !== grpc.Code.OK) {
            const decodedMessage = this.$util.getGrpcErrorMessage(trailers, process.client)
            reject({ grpcCode, message: decodedMessage })
          }
        }
      })
    })

    commit('RECEIVE_ORDERMADE_REQUEST', reply)
  },
  async getOrdermadeRequestCopy({ commit, rootState }, data) {
    const [{ GetOrdermadeRequestCopyRequest }, { OrdermadeRequestService }] = await Promise.all([
      import('~/stub/apigateway/ordermade_request/ordermade_request_pb'),
      import('~/stub/apigateway/ordermade_request/ordermade_request_pb_service')
    ])

    const params = data.params
    const metadata = commonMetaData(rootState)
    const request = new GetOrdermadeRequestCopyRequest()
    request.setRequestId(params.requestId)

    return await new Promise((resolve, reject) => {
      grpc.invoke(OrdermadeRequestService.GetOrdermadeRequestCopy, {
        request,
        metadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode })
          }
        }
      })
    })
  }
}

// mutations
export const mutations = {
  RECEIVE_ORDERMADE_REQUEST(state, data) {
    Object.assign(state, data)
  }
}
