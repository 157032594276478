// source: domain/search_outsource.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var domain_enumeration_outsource_payment_method_type_pb = require('../domain/enumeration/outsource_payment_method_type_pb.js');
goog.object.extend(proto, domain_enumeration_outsource_payment_method_type_pb);
var domain_enumeration_outsource_working_day_type_pb = require('../domain/enumeration/outsource_working_day_type_pb.js');
goog.object.extend(proto, domain_enumeration_outsource_working_day_type_pb);
var domain_enumeration_outsource_status_pb = require('../domain/enumeration/outsource_status_pb.js');
goog.object.extend(proto, domain_enumeration_outsource_status_pb);
goog.exportSymbol('proto.coconala.protobuf.domain.SearchOutsourceDomain', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.domain.SearchOutsourceDomain = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.coconala.protobuf.domain.SearchOutsourceDomain.repeatedFields_, null);
};
goog.inherits(proto.coconala.protobuf.domain.SearchOutsourceDomain, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.domain.SearchOutsourceDomain.displayName = 'proto.coconala.protobuf.domain.SearchOutsourceDomain';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.coconala.protobuf.domain.SearchOutsourceDomain.repeatedFields_ = [10,11,12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.domain.SearchOutsourceDomain.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.domain.SearchOutsourceDomain} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.domain.SearchOutsourceDomain.toObject = function(includeInstance, msg) {
  var f, obj = {
    ulid: (f = msg.getUlid()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    experienceJobCategoryId: (f = msg.getExperienceJobCategoryId()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    title: (f = msg.getTitle()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    paymentMethodType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    hourlyMinUnitPrice: (f = msg.getHourlyMinUnitPrice()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    hourlyMaxUnitPrice: (f = msg.getHourlyMaxUnitPrice()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    monthlyMinUnitPrice: (f = msg.getMonthlyMinUnitPrice()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    monthlyMaxUnitPrice: (f = msg.getMonthlyMaxUnitPrice()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    workingDayType: jspb.Message.getFieldWithDefault(msg, 9, 0),
    skillNamesList: jspb.Message.toObjectList(msg.getSkillNamesList(),
    google_protobuf_wrappers_pb.StringValue.toObject, includeInstance),
    licenseNamesList: jspb.Message.toObjectList(msg.getLicenseNamesList(),
    google_protobuf_wrappers_pb.StringValue.toObject, includeInstance),
    featureNamesList: jspb.Message.toObjectList(msg.getFeatureNamesList(),
    google_protobuf_wrappers_pb.StringValue.toObject, includeInstance),
    companyName: (f = msg.getCompanyName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    companyLogoFileName: (f = msg.getCompanyLogoFileName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    companyLogoFileUrl: (f = msg.getCompanyLogoFileUrl()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 16, 0),
    isProposed: (f = msg.getIsProposed()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    applicationCount: (f = msg.getApplicationCount()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    isBookmarked: (f = msg.getIsBookmarked()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.domain.SearchOutsourceDomain}
 */
proto.coconala.protobuf.domain.SearchOutsourceDomain.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.domain.SearchOutsourceDomain;
  return proto.coconala.protobuf.domain.SearchOutsourceDomain.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.domain.SearchOutsourceDomain} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.domain.SearchOutsourceDomain}
 */
proto.coconala.protobuf.domain.SearchOutsourceDomain.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setUlid(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setExperienceJobCategoryId(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setTitle(value);
      break;
    case 4:
      var value = /** @type {!proto.coconala.protobuf.domain.enumeration.OutsourcePaymentMethodType} */ (reader.readEnum());
      msg.setPaymentMethodType(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setHourlyMinUnitPrice(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setHourlyMaxUnitPrice(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setMonthlyMinUnitPrice(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setMonthlyMaxUnitPrice(value);
      break;
    case 9:
      var value = /** @type {!proto.coconala.protobuf.domain.enumeration.OutsourceWorkingDayType} */ (reader.readEnum());
      msg.setWorkingDayType(value);
      break;
    case 10:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.addSkillNames(value);
      break;
    case 11:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.addLicenseNames(value);
      break;
    case 12:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.addFeatureNames(value);
      break;
    case 13:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCompanyName(value);
      break;
    case 14:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCompanyLogoFileName(value);
      break;
    case 15:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCompanyLogoFileUrl(value);
      break;
    case 16:
      var value = /** @type {!proto.coconala.protobuf.domain.enumeration.OutsourceStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 17:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsProposed(value);
      break;
    case 18:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setApplicationCount(value);
      break;
    case 19:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsBookmarked(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.domain.SearchOutsourceDomain.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.domain.SearchOutsourceDomain} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.domain.SearchOutsourceDomain.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUlid();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getExperienceJobCategoryId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getTitle();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getPaymentMethodType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getHourlyMinUnitPrice();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getHourlyMaxUnitPrice();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getMonthlyMinUnitPrice();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getMonthlyMaxUnitPrice();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getWorkingDayType();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getSkillNamesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getLicenseNamesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getFeatureNamesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCompanyName();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCompanyLogoFileName();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCompanyLogoFileUrl();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      16,
      f
    );
  }
  f = message.getIsProposed();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getApplicationCount();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getIsBookmarked();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue ulid = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.getUlid = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.domain.SearchOutsourceDomain} returns this
*/
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.setUlid = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.SearchOutsourceDomain} returns this
 */
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.clearUlid = function() {
  return this.setUlid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.hasUlid = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Int32Value experience_job_category_id = 2;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.getExperienceJobCategoryId = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 2));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.domain.SearchOutsourceDomain} returns this
*/
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.setExperienceJobCategoryId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.SearchOutsourceDomain} returns this
 */
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.clearExperienceJobCategoryId = function() {
  return this.setExperienceJobCategoryId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.hasExperienceJobCategoryId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue title = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.getTitle = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.domain.SearchOutsourceDomain} returns this
*/
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.setTitle = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.SearchOutsourceDomain} returns this
 */
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.clearTitle = function() {
  return this.setTitle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.hasTitle = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional enumeration.OutsourcePaymentMethodType payment_method_type = 4;
 * @return {!proto.coconala.protobuf.domain.enumeration.OutsourcePaymentMethodType}
 */
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.getPaymentMethodType = function() {
  return /** @type {!proto.coconala.protobuf.domain.enumeration.OutsourcePaymentMethodType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.coconala.protobuf.domain.enumeration.OutsourcePaymentMethodType} value
 * @return {!proto.coconala.protobuf.domain.SearchOutsourceDomain} returns this
 */
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.setPaymentMethodType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional google.protobuf.Int32Value hourly_min_unit_price = 5;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.getHourlyMinUnitPrice = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 5));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.domain.SearchOutsourceDomain} returns this
*/
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.setHourlyMinUnitPrice = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.SearchOutsourceDomain} returns this
 */
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.clearHourlyMinUnitPrice = function() {
  return this.setHourlyMinUnitPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.hasHourlyMinUnitPrice = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Int32Value hourly_max_unit_price = 6;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.getHourlyMaxUnitPrice = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 6));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.domain.SearchOutsourceDomain} returns this
*/
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.setHourlyMaxUnitPrice = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.SearchOutsourceDomain} returns this
 */
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.clearHourlyMaxUnitPrice = function() {
  return this.setHourlyMaxUnitPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.hasHourlyMaxUnitPrice = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Int32Value monthly_min_unit_price = 7;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.getMonthlyMinUnitPrice = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 7));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.domain.SearchOutsourceDomain} returns this
*/
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.setMonthlyMinUnitPrice = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.SearchOutsourceDomain} returns this
 */
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.clearMonthlyMinUnitPrice = function() {
  return this.setMonthlyMinUnitPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.hasMonthlyMinUnitPrice = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Int32Value monthly_max_unit_price = 8;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.getMonthlyMaxUnitPrice = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 8));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.domain.SearchOutsourceDomain} returns this
*/
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.setMonthlyMaxUnitPrice = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.SearchOutsourceDomain} returns this
 */
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.clearMonthlyMaxUnitPrice = function() {
  return this.setMonthlyMaxUnitPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.hasMonthlyMaxUnitPrice = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional enumeration.OutsourceWorkingDayType working_day_type = 9;
 * @return {!proto.coconala.protobuf.domain.enumeration.OutsourceWorkingDayType}
 */
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.getWorkingDayType = function() {
  return /** @type {!proto.coconala.protobuf.domain.enumeration.OutsourceWorkingDayType} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.coconala.protobuf.domain.enumeration.OutsourceWorkingDayType} value
 * @return {!proto.coconala.protobuf.domain.SearchOutsourceDomain} returns this
 */
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.setWorkingDayType = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * repeated google.protobuf.StringValue skill_names = 10;
 * @return {!Array<!proto.google.protobuf.StringValue>}
 */
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.getSkillNamesList = function() {
  return /** @type{!Array<!proto.google.protobuf.StringValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_protobuf_wrappers_pb.StringValue, 10));
};


/**
 * @param {!Array<!proto.google.protobuf.StringValue>} value
 * @return {!proto.coconala.protobuf.domain.SearchOutsourceDomain} returns this
*/
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.setSkillNamesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.google.protobuf.StringValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.addSkillNames = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, google_protobuf_wrappers_pb.StringValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.coconala.protobuf.domain.SearchOutsourceDomain} returns this
 */
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.clearSkillNamesList = function() {
  return this.setSkillNamesList([]);
};


/**
 * repeated google.protobuf.StringValue license_names = 11;
 * @return {!Array<!proto.google.protobuf.StringValue>}
 */
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.getLicenseNamesList = function() {
  return /** @type{!Array<!proto.google.protobuf.StringValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_protobuf_wrappers_pb.StringValue, 11));
};


/**
 * @param {!Array<!proto.google.protobuf.StringValue>} value
 * @return {!proto.coconala.protobuf.domain.SearchOutsourceDomain} returns this
*/
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.setLicenseNamesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.google.protobuf.StringValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.addLicenseNames = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, google_protobuf_wrappers_pb.StringValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.coconala.protobuf.domain.SearchOutsourceDomain} returns this
 */
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.clearLicenseNamesList = function() {
  return this.setLicenseNamesList([]);
};


/**
 * repeated google.protobuf.StringValue feature_names = 12;
 * @return {!Array<!proto.google.protobuf.StringValue>}
 */
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.getFeatureNamesList = function() {
  return /** @type{!Array<!proto.google.protobuf.StringValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_protobuf_wrappers_pb.StringValue, 12));
};


/**
 * @param {!Array<!proto.google.protobuf.StringValue>} value
 * @return {!proto.coconala.protobuf.domain.SearchOutsourceDomain} returns this
*/
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.setFeatureNamesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.google.protobuf.StringValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.addFeatureNames = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, google_protobuf_wrappers_pb.StringValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.coconala.protobuf.domain.SearchOutsourceDomain} returns this
 */
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.clearFeatureNamesList = function() {
  return this.setFeatureNamesList([]);
};


/**
 * optional google.protobuf.StringValue company_name = 13;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.getCompanyName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 13));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.domain.SearchOutsourceDomain} returns this
*/
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.setCompanyName = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.SearchOutsourceDomain} returns this
 */
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.clearCompanyName = function() {
  return this.setCompanyName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.hasCompanyName = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional google.protobuf.StringValue company_logo_file_name = 14;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.getCompanyLogoFileName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 14));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.domain.SearchOutsourceDomain} returns this
*/
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.setCompanyLogoFileName = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.SearchOutsourceDomain} returns this
 */
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.clearCompanyLogoFileName = function() {
  return this.setCompanyLogoFileName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.hasCompanyLogoFileName = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional google.protobuf.StringValue company_logo_file_url = 15;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.getCompanyLogoFileUrl = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 15));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.domain.SearchOutsourceDomain} returns this
*/
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.setCompanyLogoFileUrl = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.SearchOutsourceDomain} returns this
 */
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.clearCompanyLogoFileUrl = function() {
  return this.setCompanyLogoFileUrl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.hasCompanyLogoFileUrl = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional enumeration.OutsourceStatus status = 16;
 * @return {!proto.coconala.protobuf.domain.enumeration.OutsourceStatus}
 */
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.getStatus = function() {
  return /** @type {!proto.coconala.protobuf.domain.enumeration.OutsourceStatus} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {!proto.coconala.protobuf.domain.enumeration.OutsourceStatus} value
 * @return {!proto.coconala.protobuf.domain.SearchOutsourceDomain} returns this
 */
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 16, value);
};


/**
 * optional google.protobuf.BoolValue is_proposed = 17;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.getIsProposed = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 17));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.coconala.protobuf.domain.SearchOutsourceDomain} returns this
*/
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.setIsProposed = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.SearchOutsourceDomain} returns this
 */
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.clearIsProposed = function() {
  return this.setIsProposed(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.hasIsProposed = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional google.protobuf.Int32Value application_count = 18;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.getApplicationCount = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 18));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.domain.SearchOutsourceDomain} returns this
*/
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.setApplicationCount = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.SearchOutsourceDomain} returns this
 */
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.clearApplicationCount = function() {
  return this.setApplicationCount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.hasApplicationCount = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional google.protobuf.BoolValue is_bookmarked = 19;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.getIsBookmarked = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 19));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.coconala.protobuf.domain.SearchOutsourceDomain} returns this
*/
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.setIsBookmarked = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.SearchOutsourceDomain} returns this
 */
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.clearIsBookmarked = function() {
  return this.setIsBookmarked(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.SearchOutsourceDomain.prototype.hasIsBookmarked = function() {
  return jspb.Message.getField(this, 19) != null;
};


goog.object.extend(exports, proto.coconala.protobuf.domain);
