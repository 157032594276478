<template>
  <div></div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'TheHeader',
  props: {
    isLoggedIn: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      headerType: '',
      isOpenUserMenu: false,
      isSpServiceSearchOpen: false
    }
  },
  computed: {
    ...mapState('my/header', ['hasFetchedProvider', 'isActiveSearchDrawer']),
    ...mapState('layouts', ['logoOnlyMode'])
  },
  watch: {
    $route() {
      this.setHeaderType()
    },
    logoOnlyMode() {
      this.setHeaderType()
    },
    isActiveSearchDrawer(newVal) {
      this.$util.hideZendeskWebWidgetWhileFlag(newVal)
    }
  },
  async fetch() {
    if (this.isLoggedIn && this.$ua.isFromSmartphone()) {
      await this.fetchHeader()
    } else {
      this.$store.commit('my/header/FALSE_INSTALLED_APP')
    }
    await this.setHeaderType()
  },
  mounted() {
    if (this.isLoggedIn) {
      this.fetchHeader()
    }
    // 人気のご利用シーン取得
    this.$store.dispatch('master/getUseScenes').catch(() => {})
  },
  methods: {
    async fetchHeader() {
      if (!this.hasFetchedProvider) {
        // TODO: エラー時Sentryに送信
        await this.$store.dispatch('my/fetchHeader')
      }
    },
    async setHeaderType() {
      await this.$changeHeader()
    }
  }
}
</script>
