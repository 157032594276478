import { render, staticRenderFns } from "./DButton.vue?vue&type=template&id=0607ebe2&scoped=true&"
import script from "./DButton.vue?vue&type=script&lang=js&"
export * from "./DButton.vue?vue&type=script&lang=js&"
import style0 from "./DButton.vue?vue&type=style&index=0&id=0607ebe2&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0607ebe2",
  null
  
)

export default component.exports