export const isSafari = () =>
  process.client && /safari/i.test(navigator.userAgent) && !/chrome/i.test(navigator.userAgent)
export const isDesktopSafari = () => process.client && window.safari
export const isAndroidBrowser = () => process.client && /android/i.test(navigator.userAgent)
export const isChrome = () => process.client && /chrome/i.test(navigator.userAgent)
export const isFirefox = () => process.client && /firefox/i.test(navigator.userAgent)
export const isEdge = () => process.client && /edg/i.test(navigator.userAgent)
export const isIPad = () =>
  process.client &&
  (/ipad/i.test(navigator.userAgent) ||
    (/macintosh/i.test(navigator.userAgent) && 'ontouchend' in document))
// NOTE: 「Android 2.x」のタブレットはUAにmobileが含まれるので、厳密にタブレットかどうか判定できるわけではないが、例外はごく少数
export const isAndroidTablet = () => isAndroidBrowser() && !/mobile/i.test(navigator.userAgent)
export const isTablet = () => isIPad() || isAndroidTablet()
export const botPattern =
  /Storebot-Google|bingbot|Pingdom|notebot|AdsBot-Google|startmebot|ChatGPT-User|YaK|Mediatoolkitbot|Twitterbot|Googlebot|Slackbot|Pinterestbot|about-hatenablogbot|Discordbot|Newslitbot|Ahrefs(Bot|SiteAudit)|Applebot|SemrushBot|AdsBot-Google-Mobile|Googlebot-Image|Cincraw|Semanticbot|PhxBot|Google-Safety|DotBot|PetalBot|coccocbot-web|MJ12bot|AwarioSmartBot|bitlybot|Syncbot-Web|seznambot|SiteAuditBot|SurdotlyBot|Diffbot|BSbot|Zoombot|Google-Adwords-Instant|Y!J|BrightEdge Crawler|Google-Apps-Script/
export const isUserAgentBot = (ua?: string) => {
  return process.client && botPattern.test(ua ?? navigator.userAgent)
}
