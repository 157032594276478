<template>
  <div class="simpleContainer">
    <div class="staticError">
      <h3
        class="staticError_title"
        v-html="title"
      ></h3>
      <div class="staticError_imageWrapper">
        <img
          v-if="error.imgType === IMG_TYPES.WARNING"
          class="staticError_image"
          src="@/assets/images/common/warning_illustration.svg"
          alt=""
        />
        <img
          v-else
          class="staticError_image"
          src="@/assets/images/common/error_illustration.png"
          alt="error"
        />
      </div>
      <div class="staticError_textBoxWrapper">
        <div class="staticError_textBox">
          <p class="staticError_text nl2br">{{ message }}</p>
        </div>
      </div>
      <div class="staticError_button">
        <AppButton
          type="primaryOutline"
          size="lg"
          is-full-width
          :right-icon="error.buttonRightIcon"
          @click="clickButton"
        >
          {{ buttonText }}
        </AppButton>
      </div>
    </div>
    <TheVueport />
  </div>
</template>
<script>
const TITLE_DEFAULT = 'エラーが発生しました'
const MESSAGE_DEFAULT =
  'エラーが発生しました。\n大変申し訳ありませんが、しばらく時間をおいてからお試しください。'
const TITLES = {
  400: 'リクエストが不正です',
  401: 'ログイン期限が切れました',
  404: 'ご指定のページが<br class="sp">見つかりませんでした'
}

const MESSAGES = {
  400: '操作が中断・失敗しました。',
  401: 'ログイン画面より再度ログインしてください。',
  404: '誠に申し訳ございませんが、お探しの情報は掲載期間が終了しているかログイン中のアカウントではアクセスできないなどの理由によりご覧いただけません。お手数ですが、ログイン状況などをお確かめください。'
}

import TheVueport from '@/components/organisms/TheVueport'

// vuex
import { mapGetters } from 'vuex'
import AppButton from '~/components/tell-ui/actions/AppButton/AppButton.vue'

export const IMG_TYPES = Object.freeze({
  ERROR: 'error',
  WARNING: 'warning'
})

export default {
  name: 'error',
  props: {
    error: {
      type: Object,
      default: {
        imgType: IMG_TYPES.ERROR
      },
      validator(v) {
        return !v.imgType || Object.values(IMG_TYPES).includes(v.imgType)
      }
    }
  },
  data() {
    return { IMG_TYPES }
  },
  watch: {
    isLoggedIn: function (v) {
      if (!v) {
        this.$store.dispatch('auth/clearAllModules')
      }
    }
  },
  components: {
    TheVueport,
    AppButton
  },
  computed: {
    ...mapGetters('auth', ['isLoggedIn']),
    title() {
      if (this.error.title) return this.error.title

      const httpStatus = this.error.grpcCode
        ? this.$util.grpcCodeToHttpStatusCode(this.error.grpcCode)
        : this.error.statusCode
      return TITLES[httpStatus] || TITLE_DEFAULT
    },
    message() {
      if (this.error.showsMessage) {
        return this.error.message
      }
      const httpStatus = this.error.grpcCode
        ? this.$util.grpcCodeToHttpStatusCode(this.error.grpcCode)
        : this.error.statusCode
      return MESSAGES[httpStatus] || MESSAGE_DEFAULT
    },
    url() {
      if (this.error.url) {
        return this.error.url
      }
      return this.error.grpcCode === 16 ? this.$coconala.loginURL() : this.$coconala.url('/')
    },
    buttonText() {
      if (this.error.buttonText) return this.error.buttonText

      return this.error.grpcCode === 16 ? 'ログイン画面へ' : 'TOPへ戻る'
    }
  },
  async mounted() {
    if (typeof zE === 'function') {
      // 既にscriptが読み込まれている場合は配置設定をデフォルト位置に更新
      const getZendeskWidget = () => document.getElementById('launcher')
      await this.$util.waitUntilWithLimit(() => getZendeskWidget(), 1000)
      const zendeskWidget = getZendeskWidget()
      if (zendeskWidget) {
        zendeskWidget.style.bottom = '0px'
      }
      return
    }
  },
  methods: {
    clickButton() {
      location.href = this.url
    }
  }
}
</script>

<!--本画面のhtml、cssはServalリポジトリの404.html、_container.scss,
_staticError.scss, _button.scssをベースにできるだけ作りを合わせて作成している。
本リポジトリのapp/views/error.htmlとも作りを合わせている-->
<style lang="scss" scoped>
::v-deep .sp {
  display: none;
}
.simpleContainer {
  margin: 60px auto 90px;
  padding: 0 24px;
  max-width: 700px;
  .staticError {
    text-align: center;

    &_title {
      font-weight: bold;
      font-size: 20px;
      margin: 12px 0;
      text-align: center;
      color: $ds2-color-gray-900;
    }
    &_imageWrapper {
      margin: 40px 0;
      text-align: center;
    }
    &_image {
      width: 223px;
      height: auto;
    }
    &_textBoxWrapper {
      margin: 12px auto;
      text-align: center;
    }
    &_textBox {
      display: inline-block;
      margin: 0 auto;
    }
    &_text {
      max-width: 500px;
      width: 100%;
      text-align: left;
      color: $ds2-color-gray-900;
    }
    &_button {
      margin: 12px 0;
      display: inline-block;
    }
    .button {
      &_tertiary {
        background-color: $color-white;
        border: 1px solid $ds2-color-gray-700;
        border-color: $ds2-color-gray-400 $ds2-color-gray-700 $ds2-color-gray-700
          $ds2-color-gray-400;
        border-radius: 5px;
        color: $ds2-color-gray-400;
        display: inline-block;
        font-size: 14px;
        font-weight: bold;
        height: 44px;
        line-height: 44px;
        text-align: center;
        width: 288px;

        &:visited {
          color: $ds2-color-gray-400;
        }

        &:hover {
          background-color: $ds2-color-gray-25;
          border: 1px solid $ds2-color-gray-600;
          border-color: $ds2-color-gray-200 $ds2-color-gray-600 $ds2-color-gray-600
            $ds2-color-gray-200;
        }
      }
    }
  }
}

@media (max-width: $breakpoint-s) {
  ::v-deep .sp {
    display: inline;
  }
  .simpleContainer {
    margin: 30px auto 80px;
    .staticError {
      &_title {
        font-size: 16px;
      }
    }
  }
}
</style>
