<template>
  <div
    class="c-container"
    :class="{ 'c-container-gray': isContainerGrayPages }"
  >
    <TheHeader
      :master-categories="$store.state.master.masterCategories"
      :jobs="$store.state.master.jobs"
      :blog-categories="$store.state.pages.blogs.master.blogCategoriesList"
      :auth="$store.state.auth"
      :is-logged-in="$store.getters['auth/isLoggedIn']"
      ref="header"
      v-if="!isFromCoconalaApp"
    />
    <nuxt />
    <slot name="footer">
      <ClientOnly>
        <TheFooter
          :is-logged-in="$store.getters['auth/isLoggedIn']"
          ref="footer"
          v-if="!isFromCoconalaApp"
        />
      </ClientOnly>
    </slot>
    <ClientOnly>
      <ServiceStartNotice v-if="$store.getters['auth/isLoggedIn']" />
      <FlashMessage />
      <LoginModal
        :active="isActiveLoginModal"
        @close="closeLoginModal"
      />
      <CakeNotify />
      <PortalTarget name="pageTranslate">
        <PageTranslate
          v-if="$translate.isTranslatableTarget()"
          :key="$nuxt.$route.fullPath"
        />
      </PortalTarget>
      <DLanguageSwitchModal v-if="isShowLanguageModal" />
    </ClientOnly>
  </div>
</template>
<script>
const CONTAINER_GRAY_PAGES = ['mypage-dashboard', 'mypage-dashboard_provider']

import TheHeader from '~/components/organisms/TheHeader'

// vuex
import { mapState, mapActions, mapGetters } from 'vuex'
import { LANGUAGES, FIRST_TIME_LANDING_FOR_TRANSLATE } from '~/constants/language'
import { isUserAgentBot } from '~/components/logics/platform'
import { getBrowserLanguage } from '~/components/logics/translate/getBrowserLanguage'

export default {
  name: 'LayoutDefault',
  components: {
    TheHeader,
    FlashMessage: () => import('~/components/molecules/FlashMessage'),
    CakeNotify: () => import('~/components/organisms/CakeNotify'),
    LoginModal: () => import('~/components/organisms/LoginModal'),
    ServiceStartNotice: () => import('~/components/organisms/ServiceStartNotice'),
    TheFooter: () => import('~/components/organisms/TheFooter'),
    PageTranslate: () => import('~/components/organisms/PageTranslate'),
    DLanguageSwitchModal: () => import('~/components/organisms/DS2/DLanguageSwitchModal')
  },
  data() {
    return {
      browserLanguage: LANGUAGES.JA.code
    }
  },
  provide() {
    return {
      getFixedHeaderHeight: this.getFixedHeaderHeight
    }
  },
  watch: {
    isLoggedIn: function (v) {
      if (!v) {
        this.$store.dispatch('auth/clearAllModules')
      }
    }
  },
  async mounted() {
    this.updateFixedHeaderHeight(this.getFixedHeaderHeight())
    this.$util.on('resize', window, () => {
      let totalHeight = this.getFixedHeaderHeight()
      if (this.fixedHeaderHeight !== totalHeight) {
        this.updateFixedHeaderHeight(totalHeight)
      }
    })

    // GTM(Google Tag Manager)連携用のdataLayer(変数名は固定)に情報セット。Web接客サービスのタグやCriteo等マーケで使用する
    const userId = this.user.id // Number or null
    const userKey = this.user.userKey // String or ""

    if (!Array.isArray(window.dataLayer)) {
      window.dataLayer = []
    }
    window.dataLayer.push({
      user_id: userId ? String(userId) : '',
      email: userKey
    })

    const signupEvent = this.$cookies.get('CakeCookie[signup_event]')
    const loginEvent = this.$cookies.get('CakeCookie[login_event]')
    const logoutEvent = this.$cookies.get('CakeCookie[logout_event]')

    if (userId) {
      if (loginEvent) {
        window.dataLayer.push({ event: 'login', userId })
        this.$cookies.remove('CakeCookie[login_event]')
      } else if (signupEvent && !this.user.isBusiness) {
        window.dataLayer.push({
          event: 'signup',
          userId,
          is_business: this.user.isBusiness,
          signup_attribute_purpose: this.user.signupAttributePurpose,
          usage: this.user.usage
        })
        this.$cookies.remove('CakeCookie[signup_event]')
      }
    }

    if (logoutEvent) {
      window.dataLayer.push({ event: 'logout' })
      this.$cookies.remove('CakeCookie[logout_event]')
    }

    // 生のemail情報をGTM連携する為に専用APIを追加したので、storeには保存せず上記とはロジックを別とする
    if (userKey) {
      const UserAccessor = (await import('~/api/accessors/user/user-accessor')).default
      const userApiAccessor = new UserAccessor(this.$store.state)
      userApiAccessor
        .getEmailAddress()
        .then(res => window.dataLayer.push({ raw_email: res?.emailAddress || '' }))
        .catch(() => window.dataLayer.push({ raw_email: '' }))
    } else {
      window.dataLayer.push({ raw_email: '' })
    }

    // Criteo用などのデータ(GTM上に作成された「変数」から参照されている)
    window.COCONALA = {
      IsLogin: userId ? 1 : 0,
      IsProvider: userId && this.user.isProvider ? 1 : 0,
      IsPurchase: userId && this.user.isBuyer ? 1 : 0,
      IsBusiness: userId && this.user.isBusiness ? 1 : 0
    }

    if (this.$route.query.account_switched === '1') {
      // DOMの更新後に実行しないと表示されないため、nextTickを使用
      this.$nextTick(function () {
        this.$notify({
          group: 'flash',
          title: 'アカウントが切り替わりました',
          type: 'success'
        })
      })
    } else if (this.$route.query.account_linked === '1') {
      // DOMの更新後に実行しないと表示されないため、nextTickを使用
      this.$nextTick(() => {
        this.$notify({
          group: 'flash',
          title: 'アカウントが連携されました',
          type: 'success'
        })
      })
    }

    const oneTimeNuxtToastMessageKey = process.env.config['one-time-nuxt-toast-message-key']
    const oneTimeNuxtToastMessageValue = this.$cookies.get(oneTimeNuxtToastMessageKey)
    if (oneTimeNuxtToastMessageValue) {
      this.$cookies.remove(oneTimeNuxtToastMessageKey)
      this.$nextTick(() => {
        this.$notify({
          group: 'flash',
          title: oneTimeNuxtToastMessageValue,
          type: 'success'
        })
      })
    }

    this.browserLanguage = getBrowserLanguage()
  },
  methods: {
    ...mapActions('master', ['fetchBasic']),
    ...mapActions('ui', ['updateFixedHeaderHeight', 'closeLoginModal']),
    getFixedHeaderHeight() {
      let $fixableHeaders = document.querySelectorAll('.js-fixableHeader')
      let els = Array.from($fixableHeaders)
      let heights = els.map(el => el.offsetHeight)
      return this.$util.sum(heights)
    }
  },
  computed: {
    ...mapState('ui', ['fixedHeaderHeight', 'isActiveLoginModal']),
    ...mapState('auth', ['user', 'ua']),
    ...mapGetters('auth', ['isFromCoconalaApp', 'isLoggedIn']),
    isContainerGrayPages() {
      return CONTAINER_GRAY_PAGES?.some(name => name === this.$route.name)
    },
    isShowLanguageModal() {
      return (
        !this.isLoggedIn &&
        !this.$cookies.get(FIRST_TIME_LANDING_FOR_TRANSLATE) &&
        this.browserLanguage !== LANGUAGES.JA.code &&
        !isUserAgentBot(this.ua)
      )
    }
  }
}
</script>
<style lang="scss" scoped>
.c-container {
  &-gray {
    background: $color-bg-pale;
  }
}
.c-placeholder {
  transition: height 0.5s;
}
// アカウント紐付けモーダル用定義
::v-deep .d-modal {
  &_title {
    text-align: center;
    white-space: pre-line;
  }
}
</style>

<style lang="scss">
#launcher {
  margin: 16px !important;
}

@media (max-width: $ds2-breakpoint-s) {
  #launcher {
    margin: 16px 12px !important;
  }
  .-business {
    #launcher {
      display: none;
    }
  }
}
</style>
