<template>
  <div>
    <TheHeader />
    <nuxt />
  </div>
</template>

<script>
import TheHeader from '~/components/organisms/TheHeader'

/**
 * 最小構成レイアウト
 * @type {import('vue').ComponentOptions}
 */
const minHeaderLayout = {
  name: 'LayoutPlain',
  components: {
    TheHeader
  },
  head() {
    return {
      bodyAttrs: {
        class: ['-noHeader']
      }
    }
  }
}
export default minHeaderLayout
</script>
