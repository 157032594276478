import { COLORS } from './colors'

const TAILWIND_THEME = {
  screens: {
    xs: '520px',
    sm: '768px',
    md: '960px',
    lg: '1280px',
    xl: '1440px'
  },
  colors: {
    ...COLORS
  },
  fontSize: {
    /* Display */
    'display-large': ['3.5625rem' /* 57px */, { lineHeight: '4rem' /* 64px */, fontWeight: 600 }],
    'display-medium': [
      '2.8125rem' /* 45px */,
      { lineHeight: '3.25rem' /* 52px */, fontWeight: 600 }
    ],
    'display-small': ['2.25rem' /* 36px */, { lineHeight: '2.75rem' /* 44px */, fontWeight: 600 }],
    /* Headline */
    'headline-large': ['2rem' /* 32px */, { lineHeight: '2.5rem' /* 40px */, fontWeight: 600 }],
    'headline-medium': [
      '1.75rem' /* 28px */,
      { lineHeight: '2.25rem' /* 36px */, fontWeight: 600 }
    ],
    'headline-small': ['1.5rem' /* 24px */, { lineHeight: '2rem' /* 32px */, fontWeight: 600 }],
    /* Headline Light */
    'headline-light-large': [
      '2rem' /* 32px */,
      { lineHeight: '2.5rem' /* 40px */, fontWeight: 300 }
    ],
    'headline-light-medium': [
      '1.75rem' /* 28px */,
      { lineHeight: '2.25rem' /* 36px */, fontWeight: 300 }
    ],
    'headline-light-small': [
      '1.5rem' /* 24px */,
      { lineHeight: '2rem' /* 32px */, fontWeight: 300 }
    ],
    /* Title */
    'title-large': ['1.375rem' /* 22px */, { lineHeight: '1.75rem' /* 28px */, fontWeight: 600 }],
    'title-medium': ['1.25rem' /* 20px */, { lineHeight: '1.625rem' /* 26px */, fontWeight: 600 }],
    'title-small': ['1.125rem' /* 18px */, { lineHeight: '1.5rem' /* 24px */, fontWeight: 600 }],
    /* Title Light */
    'title-light-large': [
      '1.375rem' /* 22px */,
      { lineHeight: '1.75rem' /* 28px */, fontWeight: 300 }
    ],
    'title-light-medium': [
      '1.25rem' /* 20px */,
      { lineHeight: '1.625rem' /* 26px */, fontWeight: 300 }
    ],
    'title-light-small': [
      '1.125rem' /* 18px */,
      { lineHeight: '1.5rem' /* 24px */, fontWeight: 300 }
    ],
    /* Body */
    'body-large': [
      '1rem' /* 16px */,
      {
        lineHeight: '1.5rem' /* 24px */,
        fontWeight: 400,
        letterSpacing: '0.009375em' /* 0.15px */
      }
    ],
    'body-medium': [
      '0.875rem' /* 14px */,
      {
        lineHeight: '1.25rem' /* 20px */,
        fontWeight: 400,
        letterSpacing: '0.01785714285em' /* 0.25px */
      }
    ],
    'body-small': [
      '0.75rem' /* 12px */,
      {
        lineHeight: '1rem' /* 16px */,
        fontWeight: 400,
        letterSpacing: '0.03333333333em' /* 0.4px */
      }
    ],
    /* Body Strong */
    'body-strong-large': [
      '1rem' /* 16px */,
      {
        lineHeight: '1.5rem' /* 24px */,
        fontWeight: 600,
        letterSpacing: '0.009375em' /* 0.15px */
      }
    ],
    'body-strong-medium': [
      '0.875rem' /* 14px */,
      {
        lineHeight: '1.25rem' /* 20px */,
        fontWeight: 600,
        letterSpacing: '0.01785714285em' /* 0.25px */
      }
    ],
    'body-strong-small': [
      '0.75rem' /* 12px */,
      {
        lineHeight: '1rem' /* 16px */,
        fontWeight: 600,
        letterSpacing: '0.03333333333em' /* 0.4px */
      }
    ],
    /* Label */
    'label-extra-large': [
      '1rem' /* 16px */,
      {
        lineHeight: '1.25rem' /* 20px */,
        fontWeight: 300,
        letterSpacing: '0.00625em' /* 0.1px */
      }
    ],
    'label-large': [
      '0.875rem' /* 14px */,
      {
        lineHeight: '1rem' /* 16px */,
        fontWeight: 300,
        letterSpacing: '0.00714285714em' /* 0.1px */
      }
    ],
    'label-medium': [
      '0.75rem' /* 12px */,
      {
        lineHeight: '1rem' /* 16px */,
        fontWeight: 300,
        letterSpacing: '0.04166666666em' /* 0.5px */
      }
    ],
    'label-small': [
      '0.6875rem' /* 11px */,
      {
        lineHeight: '0.875rem' /* 14px */,
        fontWeight: 300,
        letterSpacing: '0.04545454545em' /* 0.5px */
      }
    ],
    'label-extra-small': [
      '0.625rem' /* 10px */,
      {
        lineHeight: '0.75rem' /* 12px */,
        fontWeight: 300,
        letterSpacing: '0.05em' /* 0.5px */
      }
    ],
    /* Label Strong */
    'label-extra-large-strong': [
      '1rem' /* 16px */,
      {
        lineHeight: '1.25rem' /* 20px */,
        fontWeight: 600,
        letterSpacing: '0.00625em' /* 0.1px */
      }
    ],
    'label-large-strong': [
      '0.875rem' /* 14px */,
      {
        lineHeight: '1rem' /* 16px */,
        fontWeight: 600,
        letterSpacing: '0.00714285714em' /* 0.1px */
      }
    ],
    'label-medium-strong': [
      '0.75rem' /* 12px */,
      {
        lineHeight: '1rem' /* 16px */,
        fontWeight: 600,
        letterSpacing: '0.04166666666em' /* 0.5px */
      }
    ],
    'label-small-strong': [
      '0.6875rem' /* 11px */,
      {
        lineHeight: '0.875rem' /* 14px */,
        fontWeight: 600,
        letterSpacing: '0.04545454545em' /* 0.5px */
      }
    ],
    'label-extra-small-strong': [
      '0.625rem' /* 10px */,
      {
        lineHeight: '0.75rem' /* 12px */,
        fontWeight: 600,
        letterSpacing: '0.05em' /* 0.5px */
      }
    ]
  },
  extend: {
    backgroundImage: {
      'tabs-border': `linear-gradient(to bottom, rgba(0, 0, 0, 0) calc(100% - 1px), ${COLORS.gray[50]} calc(100% - 1px), ${COLORS.gray[50]} 100%)`
    },
    boxShadow: {
      'elevation-01': '0 1px 1.4px 0 rgba(71, 63, 63, 0.25)',
      'elevation-02': '0 1px 3px 0 rgba(71, 63, 63, 0.25)',
      'elevation-03': '0 1.8px 3.9px 0 rgba(71, 63, 63, 0.25)',
      'elevation-04': '0 2.3px 4px 0 rgba(71, 63, 63, 0.25)',
      'elevation-06': '0 2.3px 7px 0 rgba(71, 63, 63, 0.25)',
      'elevation-08': '0 5px 12px 0 rgba(71, 63, 63, 0.25)',
      'elevation-09': '0 7px 13px 0 rgba(71, 63, 63, 0.26)',
      'elevation-12': '0 8px 14px 0 rgba(71, 63, 63, 0.28)',
      'elevation-16': '0 7px 21px 0 rgba(71, 63, 63, 0.30)',
      'elevation-24': '0 4px 29px 0 rgba(71, 63, 63, 0.33)'
    },
    width: {
      '4.5': '1.125rem' /* 18px */,
      '120': '30rem' /* 480px */,
      '142': '35.5rem' /* 568px */,
      '200': '50rem' /* 800px */,
      'modal-fit': 'calc(100% - 2.5rem)'
    },
    height: {
      '0.25': '0.0625rem' /* 1px */,
      '3.5': '0.875rem' /* 14px */,
      '4.5': '1.125rem' /* 18px */
    },
    minWidth: {
      '3.5': '0.875rem' /* 14px */,
      '5': '1.25rem' /* 20px */,
      18: '4.5rem' /* 72px */,
      30: '7.5rem' /* 120px */
    },
    maxHeight: {
      'modal-fit': 'calc(100vh - 2.5rem)'
    },
    backdropBlur: {
      xs: '2px'
    },
    zIndex: {
      modal: '9020'
    }
  },
  iconSize: {
    sm: 16,
    md: 20,
    lg: 24
  },
  iconColor: {
    default: COLORS.black,
    white: COLORS.gray[0],
    red: COLORS.red[500],
    orange: COLORS.orange[500],
    'orange-700': COLORS.orange[700],
    primary: COLORS.primary[600],
    secondary: COLORS.secondary[700],
    'gray-900': COLORS.gray[900],
    'gray-700': COLORS.gray[700],
    'gray-600': COLORS.gray[600],
    'gray-400': COLORS.gray[400],
    'gray-200': COLORS.gray[200],
    'gray-100': COLORS.gray[100],
    'gray-50': COLORS.gray[50]
  }
} as const

export { TAILWIND_THEME }
