import { Context } from '@nuxt/types'
import type { Plugin } from '@nuxt/types'
import axios from 'axios'
import { translateTargetPage } from '../../components/logics/translate/target-page'
import { HEADER_TYPE_COOKIE } from '../../constants/cookie'

export type HeaderContent =
  | 'header/simple_mzhc_header'
  | 'header/mzhc_header'
  | 'header/simple_header'
  | 'header/before_login_header'
  | 'header/buyer_header'
  | 'header/provider_header'

/** アプリバナー非表示クッキーキー */
const APP_COOKIE_KEY = 'smart_app_banner_hidden'

/** アプリバナー表示制御 */
const showAppBanner = (ctx: Context): boolean => {
  // 会員登録トップ以外のシンプルヘッダーとビジネスプレビューではアプリバナーを出さない

  const { SIMPLE, MZHC_SIMPLE, MZHC, BUSINESS_SIMPLE, BUSINESS_BEFORE_LOGIN } =
    ctx.store.state.constants.HEADER_TYPE
  if (
    ctx.route.name !== 'register-top' &&
    [SIMPLE, MZHC_SIMPLE, MZHC, BUSINESS_SIMPLE, BUSINESS_BEFORE_LOGIN].includes(
      ctx.store.state.my.header.headerType
    )
  ) {
    return false
  }

  return (
    ctx.$ua.isFromSmartphone() &&
    ctx.$cookies.get(APP_COOKIE_KEY) !== true &&
    !ctx.store.state.my.header.installedApp &&
    !ctx.route.query.from_ad
  )
}

const isTranslatable = (ctx: Context) =>
  ctx.$translate.isTranslatableTarget() && translateTargetPage(ctx.route.name || '')

/** クライアントサイドでのヘッダ切替処理 */
const changeClientHeader = async (headerContent: HeaderContent, isTranslatable: boolean) => {
  const translatedPath = isTranslatable ? 'translated/' : ''
  const response = await axios.get<string>(
    `${process.env.config.commonHeaderUrl}/${translatedPath}${headerContent}.html`
  )
  const headerElement = document.getElementById('coconala-header')
  if (headerElement) {
    headerElement.innerHTML = response.data
  }
}

const fetchHeader = async (ctx: Context) => {
  if (!ctx.store.state.my.header.hasFetchedProvider) {
    await ctx.store.dispatch('my/fetchHeader')
  }
}

const plugin: Plugin = (ctx, inject) => {
  const _changeHeader = async (headerContent: HeaderContent) => {
    const isFromCoconalaApp = ctx.store.getters['auth/isFromCoconalaApp']
    const { headerContent: oldHeaderContent, isTranslatedHeader } = ctx.store.state.my.header
    const isTranslatableHeader = isTranslatable(ctx)
    const needChangeHeader =
      oldHeaderContent !== headerContent || isTranslatedHeader !== isTranslatableHeader
    ctx.store.commit('my/header/SET_HEADER_CONTENT', isFromCoconalaApp ? '' : headerContent)
    ctx.store.commit('my/header/SET_TRANSLATED_HEADER', isTranslatableHeader)

    if (process.client && !isFromCoconalaApp && needChangeHeader)
      await changeClientHeader(headerContent, isTranslatableHeader)
  }

  /** ヘッダ切替処理 */
  const changeHeader = async () => {
    const { route, store } = ctx
    const isLoggedIn = store.getters['auth/isLoggedIn']
    const privateAccountInfo = store.state.my.header.privateAccountInfo
    const constantsState = store.state.constants
    if (showAppBanner(ctx)) {
      store.commit('my/header/SET_APP_BANNER', ctx.$ua.isFromIos() ? 'ios' : 'android')
    }

    if (
      ((!isLoggedIn || store.state.layouts.logoOnlyMode) &&
        (store.state.register.isMzhc || privateAccountInfo.isMzhc || !!route.query.is_mzhc)) ||
      constantsState.HEADER_TYPE_MZHC_SIMPLE_PAGES?.includes(route.name)
    ) {
      await _changeHeader('header/simple_mzhc_header')
      store.commit('my/header/SET_HEADER_TYPE', constantsState.HEADER_TYPE.MZHC_SIMPLE)
      return
    }
    if (isLoggedIn && privateAccountInfo.isMzhc) {
      await _changeHeader('header/mzhc_header')
      store.commit('my/header/SET_HEADER_TYPE', constantsState.HEADER_TYPE.MZHC)
      return
    }

    if (
      store.state.layouts.logoOnlyMode ||
      constantsState.HEADER_TYPE_SIMPLE_PAGES?.includes(route.name)
    ) {
      await _changeHeader('header/simple_header')
      store.commit('my/header/SET_HEADER_TYPE', constantsState.HEADER_TYPE.SIMPLE)
      return
    }

    if (!isLoggedIn) {
      await _changeHeader('header/before_login_header')
      store.commit('my/header/SET_HEADER_TYPE', constantsState.HEADER_TYPE.BEFORE_LOGIN)
      return
    }

    if (ctx.$translate.isTranslatableTarget()) {
      await _changeHeader('header/buyer_header')
      store.commit('my/header/SET_HEADER_TYPE', constantsState.HEADER_TYPE.BUYER)
      return
    }

    if (constantsState.HEADER_TYPE_PROVIDER_ONLY_PAGES?.includes(route.name)) {
      store.commit('my/header/SET_COOKIE_HEADER_TYPE_PROVIDER')
      await _changeHeader('header/provider_header')
      store.commit('my/header/SET_HEADER_TYPE', constantsState.HEADER_TYPE.PROVIDER)
      return
    }

    if (constantsState.HEADER_TYPE_BUYER_ONLY_PAGES?.includes(route.name)) {
      await _changeHeader('header/buyer_header')
      store.commit('my/header/SET_COOKIE_HEADER_TYPE_BUYER')
      store.commit('my/header/SET_HEADER_TYPE', constantsState.HEADER_TYPE.BUYER)
      return
    }

    const headerTypeCookie = ctx.$cookies.get(HEADER_TYPE_COOKIE.NAME) || ''
    if (!headerTypeCookie) {
      await fetchHeader(ctx)

      if (store.state.my.header.isProvider) {
        store.commit('my/header/SET_COOKIE_HEADER_TYPE_PROVIDER')
        await _changeHeader('header/provider_header')
        store.commit('my/header/SET_HEADER_TYPE', constantsState.HEADER_TYPE.PROVIDER)
        return
      }

      store.commit('my/header/SET_COOKIE_HEADER_TYPE_BUYER')
      await _changeHeader('header/buyer_header')
      store.commit('my/header/SET_HEADER_TYPE', constantsState.HEADER_TYPE.BUYER)
      return
    }

    if (headerTypeCookie === HEADER_TYPE_COOKIE.VALUE_PROVIDER) {
      await _changeHeader('header/provider_header')
      store.commit('my/header/SET_HEADER_TYPE', constantsState.HEADER_TYPE.PROVIDER)
      return
    }

    await _changeHeader('header/buyer_header')
    store.commit('my/header/SET_HEADER_TYPE', constantsState.HEADER_TYPE.BUYER)
  }

  inject('changeHeader', changeHeader)
}
export default plugin
